<template>
    <div class="d-flex justify-content-center mt-10">
      <div class="container">
        <!--begin::Row-->
        <div class="row d-flex justify-content-center">
          <v-card tile></v-card>
          <div class="card card-custom card-transparent">
            <div class="card card-custom card-shadowless rounded-top-0">
              <v-card :loading="isLoggingLoading">
                <BoxLogin
                :redirect="true"
                ></BoxLogin>
              </v-card>
            </div>
            <div class="text-center py-5">
              <router-link
                  to="/events"
                  href="#"
                  class="navi-item"
              >
                <div class="navi-text">
                  <div class="font-weight-bold">{{$t("ASIDE.TABS.ROOT_NEW_RESERVATIONS")}}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import BoxLogin from "@/view/commons/BoxLogin";
import Vue from "vue";
import {VueReCaptcha} from "vue-recaptcha-v3";


Vue.use(VueReCaptcha, {siteKey: "6LdZLo4UAAAAAJ78awvAYLVjskijxdJ0A-sMOkWe"});

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isLoggingLoading"]),
  },
  components: {
    BoxLogin
  },
  watch: {
    isAuthenticated() {

    }
  }
}
</script>
